<template>
  <div>
    <p class="text-orange">
      PS：已发起变更的合同(正在审批中或者是草稿状态)，将不在当前列表内显示，请到变更列表中编辑查看！
    </p>
    <Form label-position="top">
      <Row :gutter="8">
        <i-col span="24">
          <FormItem label="选择变更合同">
            <i-select v-model="chosseContractId" filterable clearable  size="small">
              <i-option
                v-for="contract in list"
                :key="contract.id"
                :value="contract.id"
                >({{contract.code}}) {{ contract.name }}</i-option
              >
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem>
            <div
              class="p-t-10"
              v-if="chosseContractId && chosseContractId !== 0"
            >
              <Row>
                <i-col span="8"  class="title">合同编号：</i-col>
                <i-col span="16">{{ contract.code }}</i-col>
              </Row>
              <Row>
                <i-col span="8" class="title">客户：</i-col>
                <i-col span="16">{{ contract.advertiserName }}</i-col>
              </Row>
              <Row>
                <i-col span="8"  class="title">总额：</i-col>
                <i-col span="16">{{ formatMoney(contract.amount) }}</i-col>
              </Row>
              <Row>
                <i-col span="8"  class="title">合同开始日期：</i-col>
                <i-col span="16">{{ contract.startDate }}</i-col>
              </Row>
              <Row>
                <i-col span="8"  class="title">合同结束日期：</i-col>
                <i-col span="16">{{ contract.endDate }}</i-col>
              </Row>
            </div>
          </FormItem>
        </i-col>
      </Row>

      <FormItem class="text-right">
        <Button
          type="success"
          size="small"
          v-show="!isSubmit"
          :loading="loadingSumbit"
          @click="handleCreateContractChange"
          >创建合同变更</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { getContractPage } from '@/api/scp/contract'
import { getContractChangingVersionId } from '@/api/scp/contractchange'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      loadingSumbit: false,
      list: [],
      query: { pageNumber: 1, pageSize: 1000 },
      chosseContractId: 0,
      contract: {}
    }
  },
  methods: {
    getPage () {
      if (this.isAuth('ContractChange_Sale_View')) {
        this.query.userId = this.$store.getters.token.userInfo.userId
      } else {
        this.query.userId = null
      }

      this.query.statusList = JSON.stringify([5])
      this.query.isChanging = false

      getContractPage(this.query).then((res) => {
        this.list = res.list
      })
    },
    handleCreateContractChange () {
      if (this.loadingSumbit) { return }

      this.loadingSumbit = true
      getContractChangingVersionId({ contractId: this.chosseContractId }).then(
        (res) => {
          if (res && !res.errcode) {
            this.chosseContractId = 0
            this.getPage()
            this.$Notice.success({ desc: '合同变更创建成功！' })
            debugger
            this.$emit('on-create', res.contractId, res.versionId)
          }
          this.loadingSumbit = false
        }
      )
    },
    formatMoney (money) {
      return toMoney(money || 0)
    }
  },
  computed: {
    isSubmit () {
      return this.chosseContractId === 0 || this.chosseContractId === undefined
    },
    updateTime () {
      return this.$store.state.contract.beginUpdate
    }
  },
  created () {
    this.getPage()
  },
  watch: {
    chosseContractId (val) {
      if (val && val !== 0) {
        this.contract = this.list.find((x) => x.id === val)
      } else {
        this.contract = {}
      }
    },
    updateTime () {
      this.getPage()
    }
  }
}
</script>
